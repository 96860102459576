<template>
  <div class="equipment-inventory-schedule-add-container">
    <div class="operate">
      <dsn-button
          size="medium"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t("commbtn.back") }}
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save
          size="medium"
          @click.native="checkAdd(['scheduleFormRef'])">{{ this.$t("commbtn.save") }}
      </dsn-button-save>
      <dsn-button
          size="medium"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['scheduleFormRef'])"
      >{{ this.$t("commbtn.reset") }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="scheduleForm"
          ref="scheduleFormRef"
          :inline="true"
          :rules="userFormRules"
          class="equipment-main-form"
          style="display: none"
      >
      </el-form>
      <dsn-button-save
          size="medium"
          @click.native="handleStorageDialogOpen">{{ '选择储位' }}
      </dsn-button-save>
      <el-tabs type="border-card">
        <el-tab-pane class="schedule-detail-info">
          <span slot="label">盘点计划明细</span>
          <div class="operation">
<!--            <dsn-button-->
<!--                size="medium"-->
<!--                type="success"-->
<!--                icon="el-icon-folder-add"-->
<!--                :disabled="scheduleForm.storageId===null|| this.scheduleForm.storageId.length===0"-->
<!--                v-loading="importLoadingBtn"-->
<!--                @click.native="handleDetailAdd"-->
<!--            >{{ this.$t("commbtn.add") }}-->
<!--            </dsn-button>-->
          </div>
          <!--          <dsn-table-->
          <!--              border-->
          <!--              resizable-->
          <!--              show-overflow-->
          <!--              ref="scheduleItemTableRef"-->
          <!--              keep-source-->
          <!--              :data="scheduleForm.scheduleDetailList"-->
          <!--              :edit-config="{trigger: 'click', mode: 'cell'}">-->
          <!--            <el-table-column type="selection" width="60"></el-table-column>-->
          <!--            <el-table-column prop="equipmentAssetNo" :label="this.$t('equipment.props.assetNo')"-->
          <!--                              align="center"></el-table-column>-->
          <!--            <el-table-column prop="equipmentSapNo" :label="this.$t('equipment.props.sapAssetNo')"-->
          <!--                              align="center"></el-table-column>-->
          <!--            &lt;!&ndash;            <el-table-column prop="equipmentSno" :label="this.$t('equipment.props.sno')"&ndash;&gt;-->
          <!--            &lt;!&ndash;                              align="center"></el-table-column>&ndash;&gt;-->
          <!--            <el-table-column prop="brandName" :label="this.$t('equipment.props.brand')"-->
          <!--                              align="center"></el-table-column>-->
          <!--            <el-table-column prop="equipmentName" :label="this.$t('equipment.props.name')"-->
          <!--                              align="center"></el-table-column>-->
          <!--            <el-table-column prop="modelType" :label="this.$t('equipment.props.modelType')"-->
          <!--                              align="center"></el-table-column>-->
          <!--            <el-table-column prop="acquisitionDate" :label="this.$t('equipment.props.acquisitionDate')"-->
          <!--                              align="center"></el-table-column>-->
          <!--            <el-table-column prop="storageName" :label="this.$t('equipment.props.storage')"-->
          <!--                              align="center"></el-table-column>-->
          <!--            <el-table-column prop="originValue" :label="'原值'"-->
          <!--                              align="right"></el-table-column>-->
          <!--            <el-table-column prop="originValue" :label="'净值'"-->
          <!--                              align="right"></el-table-column>-->
          <!--            <el-table-column prop="checkStatusDesc" :label="this.$t('equipment.props.checkStatus')"-->
          <!--                              :edit-render="{name: '$input', props: {type: 'text'}}" align="center"-->
          <!--                              fixed="right"></el-table-column>-->
          <!--          </dsn-table>-->
          <dsn-table
				v-loading="equipmentImportDialog.loading"
              border
              resizable
              show-overflow
              ref="scheduleItemTableRef"
              :data="scheduleDetailList">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column prop="storage" label="储位"
                              align="center" width="300">
              <template slot-scope="scope">
                <span>{{ scope.row.storage == null ? null : scope.row.storage.storageName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="keepers" label="储位保管人"
                              align="center" width="350">
              <template slot-scope="scope">
                <span>{{ keeperNameFormat(scope) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="needToCount" label="应盘数量"
                              align="right" width="100"></el-table-column>
            <el-table-column prop="actualCount" label="实盘数量"
                              align="right" width="100"></el-table-column>
            <el-table-column prop="unCount" label="未盘数量"
                              align="right" width="100"></el-table-column>
            <el-table-column prop="errorCount" label="储位异常" width="100"
                              align="right"
            ></el-table-column>
            <el-table-column prop="status" label="执行状态" show-overflow-tooltip align="center" width="150">
              <template slot-scope="scope">
                <span>{{ storageInventoryScheduleFormat(scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="'操作'"
                              align="center" width="250">
              <!--          <template s></template>-->
              <template slot-scope="scope">
                <vxe-button size="medium" :content="$t('commbtn.detail')" status="primary"
                            @click="handleViewItemDetail(scope.row)"></vxe-button>

                <vxe-button size="medium" :content="$t('commbtn.delete')" status="danger"
                            @click="deleteViewItem(scope.row)"></vxe-button>
              </template>
            </el-table-column>
          </dsn-table>
        </el-tab-pane>
      </el-tabs>
      <el-dialog label="储位选择" width="30%" :visible="!storageSelectHidden" @close="handleStorageDialogClose1">
        <div class="storage-select-container">
          <!-- <el-tree-select ref="treeSelect" v-model="scheduleForm.storageId" :selectParams="selectParams"
                          :treeParams="treeParams"
                          :isSingle="true"/> -->
			<el-cascader
			    :options="this.treeParams.data"
			    :props="props"
			    collapse-tags
				v-model="newSelectStorageId"
			    clearable></el-cascader>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleStorageDialogClose">取 消</el-button>
            <el-button type="primary" @click="handleStorageDialogClose">确 定</el-button>
        </span>
      </el-dialog>
      <!--      <DsnSelectDialog-->
      <!--          width="1000px"-->
      <!--          title="设备设施列表"-->
      <!--          :isSingle="true"-->
      <!--          :helpText="equipmentImportDialog.helpText"-->
      <!--          :tableData="equipmentImportDialog.equipmentList"-->
      <!--          v-model="equipmentImportDialog.selectedEquipmentList"-->
      <!--          :visible.sync="equipmentImportDialog.equipmentDialog"-->
      <!--          @confirm="handleSelectionEquipments"-->
      <!--          @cancle="handleCancelSelection"-->
      <!--          :maxShowLimit="1000"-->
      <!--          rowKey="id"-->
      <!--          :isLoading="equipmentImportDialog.loading"-->
      <!--          keyValue="id"-->

      <!--          ref="equipmentsSelectRef"-->
      <!--      >-->
      <!--        <template slot="body">-->
      <!--          <dsn-table-column type="checkbox" width="60"></dsn-table-column>-->
      <!--          <dsn-table-column prop="sno" label="设备编号"></dsn-table-column>-->
      <!--          <dsn-table-column prop="assetNo" label="固定资产编号"/>-->
      <!--          <dsn-table-column prop="sapAssetNo" label="SAP编号"/>-->
      <!--          <dsn-table-column prop="name" label="设备名称"/>-->
      <!--          <dsn-table-column prop="modelType" label="设备型号"/>-->
      <!--          <dsn-table-column prop="acquisitionDate" label="入厂日期"/>-->
      <!--        </template>-->
      <!--      </DsnSelectDialog>-->

      <DsnSelectDialog
          width="1000px"
          :showFooter="false"
          title="明细列表"
          :isSingle="true"
          :helpText="equipmentImportDialog.helpText"
          :tableData="equipmentImportDialog.equipmentList"
          v-model="equipmentImportDialog.selectedEquipmentList"
          :visible.sync="equipmentImportDialog.equipmentDialog"
          @cancle="handleDetailClose"
          rowKey="id"
          :showArrow="false"
          :isLoading="equipmentImportDialog.loading"
          keyValue="id"
          ref="inventoryDetailRef"
      >
        <template slot="body">
			<!-- <vxe-table border :header-cell-style="{'font-size':'17px','background':'#4e81bd','font-family':'微软雅黑','font-weight':'normal','color':'#ffffff','border':'1px #e8eaec solid'}":data="equipmentImportDialog.equipmentList"> -->
				<vxe-table-column type="seq" label="#" width="50" align="center"></vxe-table-column>
				<vxe-table-column prop="equipmentAssetNo" label="资产编号"></vxe-table-column>
				<vxe-table-column prop="equipmentName" label="设备名称"></vxe-table-column>
				<vxe-table-column prop="brandName" label="品牌"></vxe-table-column>
				<vxe-table-column prop="modelType" label="设备型号"/>
				<vxe-table-column prop="images" label="图片" align="center" style="display: flex">
				  <template slot-scope="scope">
				    <div style="display: flex;justify-content: center">
				      <el-image v-if="scope.row.imagePreview!=null"
				                style="width: 32px; height: 32px"
				                :src="scope.row.imagePreview"
				                :preview-src-list="scope.row.imageList" :z-index="9999">
				        <div slot="error" class="image-slot">
				          <i class="el-icon-picture-outline"></i>
				        </div>
				      </el-image>
				      <span v-else></span>
				    </div>
				  </template>
				</vxe-table-column>
				<vxe-table-column prop="checkStatus" label="盘点状态" align="center">
				  <template slot-scope="scope">
				    <span v-if="scope.row.checkStatus==='1'">未盘</span>
				    <span v-else-if="scope.row.checkStatus==='2'">储位异常</span>
				    <span v-else-if="scope.row.checkStatus==='3'">已盘</span>
				    <span v-else>未知状态</span>
				  </template>
				</vxe-table-column>
				<!-- </vxe-table> -->
				
				
          <!-- <vxe-table-column type="seq" label="#" width="50" align="center" style="background:#4e81bd;font-family:微软雅黑;color:#ffffff;border:1px #e8eaec solid;"></vxe-table-column>
          <vxe-table-column prop="equipmentAssetNo" label="资产编号"></vxe-table-column>
          <vxe-table-column prop="equipmentName" label="设备名称"></vxe-table-column>
          <vxe-table-column prop="brandName" label="品牌"></vxe-table-column>
          <vxe-table-column prop="modelType" label="设备型号"/>
          <vxe-table-column prop="images" label="图片" align="center" style="display: flex">
            <template slot-scope="scope">
              <div style="display: flex;justify-content: center">
                <el-image v-if="scope.row.imagePreview!=null"
                          style="width: 32px; height: 32px"
                          :src="scope.row.imagePreview"
                          :preview-src-list="scope.row.imageList" :z-index="9999">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <span v-else></span>
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column prop="checkStatus" label="盘点状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.checkStatus==='1'">未盘</span>
              <span v-else-if="scope.row.checkStatus==='2'">储位异常</span>
              <span v-else-if="scope.row.checkStatus==='3'">已盘</span>
              <span v-else>未知状态</span>
            </template>
          </vxe-table-column> -->
        </template>
      </DsnSelectDialog>
    </div>
  </div>

</template>
<script>
import {getEquipmentsByStorages} from "@/api/efms/equipments/equipments.api";
// import TreeSelect from 'vue2-tree-select'
// import 'vue2-tree-select/dist/css/index.css'
import {getStorageList} from "@/api/efms/equipments/equipment.storage.api";
import arrayToTree from "array-to-tree";
import {addInventorySchedule} from "@/api/efms/inventory_schedule/inventory_schedule.api";
import {convertToGroupList} from "@/until/biz/inventoryScheduleFuc";

export default {
  name: 'equipmentAdd',
  components: {},
  data() {
    return {
      isEditStation: false, //是否可编辑站位
      scheduleForm: {
        scheduleNo: null,
        equipmentCategoryId: null,
        equipmentSubcategoryId: null,
        scheduleStartDate: null,
        scheduleEndDate: null,
        executorId: null,
        storageId: [],
        scheduleDetailList: [],
      },
      // scheduleDetailList: [],
      selectionList: [],
      labelWidth: "120px",
      equipmentCategoryList: [],
      equipmentSubcategoryList: [],
      conditionList: [],
      mainDeptList: [],
      brandList: [],
      executorList: [],
      selectParams: {
        multiple: true,
        clearable: true,
        placeholder: '选择储位'
      },
      treeParams: {
        clickParent: true,
        filterable: false,
        'check-strictly': true,
        'default-expand-all': true,
        'expand-on-click-node': false,
        data: [],
        props: {
          children: 'children',
          label: 'label',
          disabled: 'false',
          value: 'value'
        }
      },
      storageFullList: [],
      storageNodeList: [],
      equipmentImportDialog: {
        helpText: (data) => {
          return data
        },
        equipmentList: [],
        selectedEquipmentList: [],
        loading: false,
        equipmentDialog: false,
        equipmentForm: {
          equipmentCategoryId: null,
          equipmentSubcategoryId: null,
        }
      },
      importLoadingBtn: false,
      storageSelectHidden: true,
      scheduleDetailList: [],
	  
	  props: { multiple: true },
	  newSelectStorageId:[],
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
    this.initSelectData();
    // console.info(this.$refs.treeSelect)
  },
  activated() {
    this.initSelectData();
  },
  computed: {
    userFormRules: function () {
      let that = this
      return {
        scheduleNo: [{required: false, message: "请输入盘点单号", trigger: "change"}],
      }
    }
  },
  // watch: {
  //   'storageSelectHidden': {
  //     handler(n, o) {
  //       if (!n){
  //         console.info(n)
  //          this.$nextTick(()=>{
  //            this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
  //          })
  //       }
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  methods: {
    handleBack() {
      this.$router.pushAndClear(
          {name: "equipmentInventorySchedule"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      console.info(count)
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      const postData = {};
      postData['scheduleNo'] = this.scheduleForm.scheduleNo
      postData['storageId'] = this.scheduleForm.storageId.length > 0 ? this.scheduleForm.storageId[0] : null
      if (postData['storageId'] != null && this.storageFullList.length > 0) {
        let targetList = this.storageFullList.filter(s => s.id === postData['storageId'])
        if (targetList != null && targetList.length === 1) {
          postData['storageName'] = targetList[0].equipmentStorageName
        }
      }
      postData['archivedStatus'] = "0"
      postData['scheduleStatus'] = "WAIT"
      postData['scheduleStatusId'] = "1"
      postData['scheduleFinishDate'] = null
      postData['scheduleDetailList'] = this.scheduleForm.scheduleDetailList
      console.info(JSON.stringify(postData))
      addInventorySchedule(postData).then(result => {
        const {status, data} = result;
        if (status === 200 && data.resultCode === 0) {
          this.notification().success({
            body: "保存成功",
          });
          this.handleBack();
          return;
        }
        this.notification().error({
          body: data.errMsg,
        });
      })
    },
    handleReset(formName) {
      // if (this.operateType === "add") {
      //   formName.forEach((element) => {
      //     this.$refs[element].resetFields();
      //   });
      // }

      //重置选择得储位
      this.scheduleForm.storageId = null;
      this.scheduleForm.scheduleDetailList = [];
      this.scheduleDetailList = [];
      // this.scheduleDetailList
    },
    initSelectData() {
      // this.initCategoryList();
      // this.initBrandList();
      // this.initConditionList();
      // this.initUserList();
      this.initStorageList();
    },
    initCategoryList() {
      this.equipmentCategoryList.push({
        id: '1',
        label: '电脑',
        value: '电脑',
        key: 'computer'
      }, {
        id: '2',
        label: '消防设备',
        value: '消防设备',
        key: 'fire'
      })
    },
    initSubcategory(categoryId) {

    },
    initBrandList() {
      this.brandList.push({
            id: '1',
            label: '华硕',
            value: '华硕',
            key: 'asus'
          },
          {
            id: '2',
            label: '小米',
            value: 'xiaomi',
            key: 'xiaomi'
          })
    },
    initConditionList() {
      this.conditionList.push(
          {
            id: '1',
            label: '正常',
            value: 'ok',
            key: 'ok'
          }, {
            id: '2',
            label: '停用',
            value: 'stop',
            key: 'stop'
          }
      )
    },
    initUserList() {
      this.executorList.push(
          {
            id: '1',
            label: 'user1',
            value: '1',
            key: '1'
          }, {
            id: '2',
            label: 'user2',
            value: '2',
            key: '2'
          }
      )
    },
    handleDetailTableSelectionChange() {

    },
    selectCheckBox() {

    },
    handleDetailAdd() {
      // this.equipmentImportDialog.equipmentDialog = true
      if (this.scheduleForm.storageId === null || this.scheduleForm.storageId.length === 0) {
        this.$message.error("请选择储位")
      } else {
        this.initImportEquipmentList()
      }
    },
    handleDetailEdit() {

    },
    handleDetailDeleteCheck() {

    },
    handleSelectionEquipments(val) {
      this.equipmentImportDialog.selectedEquipmentList = val
      if (this.equipmentImportDialog.selectedEquipmentList != null && this.equipmentImportDialog.selectedEquipmentList.length > 0) {
        let temps = this.equipmentImportDialog.selectedEquipmentList.map((v) => {
          return {
            id: null,
            storageId: v.storageId,
            storageName: v.storageName,
            scheduleId: null,
            scheduleNo: null,
            equipmentId: v.id,
            equipmentSno: v.sno,
            equipmentName: v.name,
            checkStatusId: '0',
            checkStatusDesc: "未盘点",
            checkResult: null,
            checkedBy: null,
            checkedDate: null,
            equipmentAssetNo: v.assetNo,
            equipmentModelType: v.modelType,
            equipmentSapNo: v.sapAssetNo,
            equipmentRunStatusId: v.runningStatusId,
            equipmentRunStatusDesc: v.runningStatusDesc,
            equipmentConditionId: v.conditionId,
            equipmentConditionDesc: v.conditionDesc,
            acquisitionDate: v.acquisitionDate,
            brandName: v.brandName,
            modelType: v.modelType,
          }
        })
        this.scheduleForm.scheduleDetailList = [...temps]
        this.equipmentImportDialog.equipmentDialog = false
      }
    },
    handleCancelSelection() {
      this.equipmentImportDialog.equipmentDialog = false
    },
    queryWorkCenter() {

    },
    handleResetWorkCenterCondition() {

    },
    onCategoryChange(val) {
      console.info(val)
      this.equipmentImportDialog.equipmentForm.equipmentCategoryId = val
    },
    onSubCategoryChange(val) {
      this.equipmentImportDialog.equipmentForm.equipmentSubcategoryId = val
    },
    onStorageChange(value) {

    },
    initStorageList() {
      let postData = {
        page: 0,
        pageSize: 0
      }
      getStorageList(postData).then(res => {
        const {status, data} = res
        if (status != null && data != null && data.data.length > 0) {
          this.storageFullList = [...data.data]
          let tempList = data.data.map(v => {
            return {
              label: v.equipmentStorageName,
              id: v.id,
              value: v.id,
              parentId: v.parentId == null || v.parentId === "" ? null : v.parentId,
              children: null,
              keepers: v.keepers
            }
          })
          let resultList = arrayToTree(tempList, {
            parentProperty: 'parentId',
            childrenProperty: 'children'
          })
          let jsonArray = JSON.stringify(resultList)
          this.storageNodeList = [...JSON.parse(jsonArray)]
          this.treeParams.data = [...JSON.parse(jsonArray)]
          this.$nextTick(() => {
            // this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
          })
        } else {
          this.storageNodeList = []
        }
      })
    },
    initImportEquipmentList() {
      if (this.scheduleForm.storageId == null) {
        this.$message.warning("请选择储位")
      } else {
        this.equipmentImportDialog.loading = true
        this.importLoadingBtn = true
		let params = {
				storageIds: this.scheduleForm.storageId.toString(),
		};
        getEquipmentsByStorages(params).then(res => {
          const {status, data} = res
          if (status != null && status === 200) {
            this.importLoadingBtn = false
            if (data != null && data.data.length > 0) {
              let temps = data.data.map((v) => {
                return {
                  id: null,
                  storageId: v.storageId,
                  storageName: v.storageName,
                  scheduleId: null,
                  scheduleNo: null,
                  equipmentId: v.id,
                  equipmentSno: v.sno,
                  equipmentName: v.name,
                  checkStatusId: '0',
                  checkStatusDesc: "未盘点",
                  checkResult: null,
                  checkedBy: null,
                  checkedDate: null,
                  equipmentAssetNo: v.assetNo,
                  equipmentModelType: v.modelType,
                  equipmentSapNo: v.sapAssetNo,
                  equipmentRunStatusId: v.runningStatusId,
                  equipmentRunStatusDesc: v.runningStatusDesc,
                  equipmentConditionId: v.conditionId,
                  equipmentConditionDesc: v.conditionDesc,
                  acquisitionDate: v.acquisitionDate,
                  brandName: v.brandName,
                  modelType: v.modelType,
                  keepers: v.keepers,
                  imgIds: v.imgIds,
                }
              })
              this.scheduleForm.scheduleDetailList = [...temps]
              let results = convertToGroupList(temps, this.storageFullList)
              this.scheduleDetailList = [...results]
            } else {
              // this.equipmentImportDialog.equipmentList = []
              this.scheduleForm.scheduleDetailList = []
            }
            this.equipmentImportDialog.loading = false
          } else {
            this.equipmentImportDialog.equipmentList = []
            this.importLoadingBtn = false
            console.info('err')
            this.equipmentImportDialog.loading = false
          }
        }).catch(err => {
          console.info(err)
        })
      }
    },
    handleStorageDialogClose() {
		// console.log('this.treeParams.data ---------------------------',this.treeParams.data);
		// console.log('this.newSelectStorageId ---------------------------',this.newSelectStorageId);
		
		let tempArr=[];
		for(let item in this.newSelectStorageId){
			for(let sonItem in this.newSelectStorageId[item]){
				
				let findobj = tempArr.findIndex(finditem=> finditem === this.newSelectStorageId[item][sonItem]);
				
				if(findobj && findobj != null && findobj == -1){
					tempArr.push(this.newSelectStorageId[item][sonItem]);
				}
			}
		}
		// console.log('tempArr ---------------------------',tempArr);
		this.scheduleForm.storageId = tempArr;
		
      this.storageSelectHidden = true;
      if (this.scheduleForm.storageId != null && this.scheduleForm.storageId.length > 0) {
        this.handleDetailAdd();
      }
    },
	handleStorageDialogClose1(){
		this.storageSelectHidden = true;
	},
    handleStorageDialogOpen() {
      this.scheduleForm.storageId = [];
      this.initSelectData();
      this.storageSelectHidden = false;
      let that = this;

      // setTimeout(() => {
      //   that.$refs.treeSelect.treeDataUpdateFun(that.treeParams.data);
      // }, 1000)
      // this.$nextTick(() => {
      //   console.info(that.treeParams.data)
      //   that.$refs.treeSelect.treeDataUpdateFun(that.treeParams.data);
      // }, 300);
    },
    storageInventoryScheduleFormat(data) {
      if (data === '0') {
        return "盘点中"
      } else if (data === '1') {
        return "已完成"
      } else {
        return "待盘点"
      }
    },
    handleViewItemDetail(data) {
      console.info(data)
      let dataList = JSON.parse(JSON.stringify(data.detail));
      if (dataList != null && dataList.length > 0) {
        dataList.forEach(v => {
          console.info(v)
          let imageIds = v.imgIds;
          let imageUrl = this.convertEquipmentImage(imageIds)
          if (imageUrl != null && imageUrl.length > 0) {
            Object.assign(v, {
              imagePreview: imageUrl[0],
              imageList: imageUrl,
            })
          }
        })
      }
      this.equipmentImportDialog.equipmentDialog = true
      this.equipmentImportDialog.equipmentList = [...dataList]
    },
    keeperNameFormat(scope) {
      let storage = scope.row.storage;
      let nameArr = [];
      if (storage != null && storage.keepers != null && storage.keepers.length > 0) {

        storage.keepers.forEach(v => {
          nameArr.push(v.realname)
        })
      }
      return nameArr.length > 0 ? nameArr.join(",") : null
    },
    handleDetailClose() {
      this.equipmentImportDialog.equipmentDialog = false
    },
    deleteViewItem(data) {
      console.info(data);
      let storage = data.storage;
      if (storage != null) {
        let storageId = storage.id;
        let remainItems = this.scheduleForm.scheduleDetailList.filter(s => s.storageId !== storageId)
        this.scheduleForm.scheduleDetailList = [...remainItems];
        let remainStorage = this.scheduleDetailList.filter(s => s.storage.id !== storageId);
        this.scheduleDetailList = [...remainStorage]
      }
    },
    convertEquipmentImage(imgIds) {
      let result = [];
      if (imgIds != null && imgIds.length > 0) {
        let temp = imgIds.map(x => {
          // return `${VUE_APP_URL}/api/equipments/download/${x}`;
          return `https://cpm.deartsz.com/api/equipments/download/${x}`;
        })
        result = [...temp]
      }
      return result;
    },
  }
}
</script>
<style lang="scss">

.equipment-inventory-schedule-add-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .equipmentFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .equipment-main-form {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }

  .equipment-query-form {
    .el-form-item__label {
      padding: 0;
    }
  }
  .vxe-header--column{
	  background:#4e81bd;font-family:微软雅黑;color:#ffffff;border-right:1px #e8eaec solid;
  }
}

.storage-select-container {
  height: 400px;
}
</style>
