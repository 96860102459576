<template>
	<div class="equipment-inventory-schedule-edit-container">
		<div class="operate">
			<dsn-button size="medium" icon="el-icon-back" type="primary" @click.native="handleBack">{{ this.$t('commbtn.back') }}</dsn-button>
			<!--      <dsn-button-save-->
			<!--          @click.native="checkAdd('materialGroupForm')"-->
			<!--          v-if="hasUpdateBtnAuth"-->
			<!--      >保存-->
			<!--      <dsn-button-save-->
			<!--          @click.native="checkAdd(['equipmentFormRef'])">{{ this.$t('commbtn.save') }}-->
			<!--      </dsn-button-save-->
			<!--      >-->
			<!--      <dsn-button-->
			<!--          size="small"-->
			<!--          type-->
			<!--          plain-->
			<!--          icon="el-icon-refresh"-->
			<!--          class="reset-button"-->
			<!--          @click.native="handleReset(['equipmentFormRef'])"-->
			<!--      >{{ this.$t('commbtn.reset') }}-->
			<!--      </dsn-button>-->
		</div>
		<div class="info">
			<el-form :model="scheduleForm" ref="equipmentFormRef" :inline="true" :rules="userFormRules" class="equipment-main-form">
				<el-row>
					<el-col :span="24">
						<el-form-item :label="this.$t('inventorySchedule.fields.scheduleNo')" prop="scheduleNo" :label-width="labelWidth">
							<dsn-input v-model.trim="scheduleForm.scheduleNo" placeholder="请输入计划编号" :disabled="true"></dsn-input>
						</el-form-item>
						<!--            <el-form-item :label="this.$t('equipments.fields.storage')" prop="storageId"-->
						<!--                          :label-width="labelWidth">-->
						<!--              <el-tree-select ref="treeSelect" v-model="scheduleForm.storageId" :selectParams="selectParams"-->
						<!--                              :treeParams="treeParams" :disabled="true"-->
						<!--                              :isSingle="true"/>-->
						<!--            </el-form-item>-->
					</el-col>
				</el-row>
			</el-form>
			<el-tabs type="border-card">
				<el-tab-pane class="schedule-detail-info">
					<span slot="label">盘点计划明细</span>

					<div class="operation">
						<!--            <dsn-button-->
						<!--                size="small"-->
						<!--                type="success"-->
						<!--                icon="el-icon-folder-add"-->
						<!--                @click.native="handleDetailAdd"-->
						<!--            >新增-->
						<!--            </dsn-button>-->
						<!--            <dsn-button-->
						<!--                size="small"-->
						<!--                type="primary"-->
						<!--                icon="el-icon-edit"-->
						<!--                :disabled="selectionList.length !== 1"-->
						<!--                @click="handleDetailEdit"-->
						<!--                style="margin-left:0"-->
						<!--            >修改-->
						<!--            </dsn-button>-->
						<!--            <dsn-button-->
						<!--                size="small"-->
						<!--                type="danger"-->
						<!--                icon="el-icon-delete"-->
						<!--                style="margin-left:0"-->
						<!--                :disabled="selectionList.length <= 0"-->
						<!--                @click="handleDetailDeleteCheck"-->
						<!--            >删除-->
						<!--            </dsn-button>-->
					</div>

					<!--          <dsn-table-->
					<!--              @row-click="selectCheckBox"-->
					<!--              ref="multipleTable"-->
					<!--              :data="scheduleDetailList"-->
					<!--              tooltip-effect="dark"-->
					<!--              style="width: 100%"-->
					<!--              border="true"-->
					<!--              @selection-change="handleDetailTableSelectionChange"-->
					<!--          >-->
					<!--            <el-table-column type="selection" width="55"/>-->
					<!--            <el-table-column type="index" label="序号"/>-->
					<!--            <el-table-column prop="sno" label="设备设施编号"/>-->
					<!--            <el-table-column prop="name" label="名称"/>-->
					<!--            <el-table-column prop="deptId" label="所在部门"/>-->
					<!--            <el-table-column prop="positionId" label="所在库位"/>-->
					<!--          </dsn-table>-->
					<vxe-table border resizable show-overflow ref="scheduleItemTableRef" :loading="inventoryGroupLoading" :data="scheduleDetailList":header-cell-style="{'font-size':'17px','background':'#4e81bd','font-family':'微软雅黑','font-weight':'normal','color':'#ffffff','border':'1px #e8eaec solid'}"height="600">
						<vxe-table-column type="seq" width="60" align="center"></vxe-table-column>
						<vxe-table-column field="storage" title="储位" align="center" width="200">
							<template slot-scope="scope">
								<span>{{ scope.row.storage == null ? null : scope.row.storage.storageName }}</span>
							</template>
						</vxe-table-column>
						<vxe-table-column field="keepers" title="储位保管人" align="center" width="250">
							<template slot-scope="scope">
								<span>{{ keeperNameFormat(scope) }}</span>
							</template>
						</vxe-table-column>
						<vxe-table-column field="needToCount" title="应盘数量" align="center" width="100"></vxe-table-column>
						<vxe-table-column field="actualCount" title="实盘数量" align="center" width="100"></vxe-table-column>
						<vxe-table-column field="unCount" title="未盘数量" align="center" width="100"></vxe-table-column>
						<vxe-table-column field="errorCount" title="储位异常" width="150" align="center"></vxe-table-column>
						<vxe-table-column prop="status" label="执行状态" show-overflow-tooltip align="center" width="150">
							<template slot-scope="scope">
								<span>{{ storageInventoryScheduleFormat(scope.row.status) }}</span>
							</template>
						</vxe-table-column>
						<vxe-table-column :title="'操作'" fixed="right" align="center" width="80">
							<!--          <template s></template>-->
							<template slot-scope="scope">
								<vxe-button size="medium" :content="$t('commbtn.detail')" status="primary" @click="handleViewItemDetail(scope)"></vxe-button>
							</template>
						</vxe-table-column>
					</vxe-table>
				</el-tab-pane>
			</el-tabs>
			<DsnSelectDialog
				width="1000px"
				:showFooter="false"
				:title="showStoreTitle"
				:isSingle="true"
				:helpText="equipmentImportDialog.helpText"
				:tableData="equipmentImportDialog.equipmentList"
				v-model="equipmentImportDialog.selectedEquipmentList"
				:visible.sync="equipmentImportDialog.equipmentDialog"
				@cancle="handleCancleWorCenter"
				rowKey="id"
				:showArrow="false"
				:isLoading="equipmentImportDialog.loading"
				keyValue="id"
				ref="equipmentSelectSingleDialog"
			>
				<template slot="body">
					<vxe-table-column type="seq" label="#" width="50" align="center"></vxe-table-column>
					<vxe-table-column prop="equipmentAssetNo" label="资产编号"></vxe-table-column>
					<vxe-table-column prop="equipmentName" label="设备名称"></vxe-table-column>
					<vxe-table-column prop="brandName" label="品牌"></vxe-table-column>
					<vxe-table-column prop="modelType" label="设备型号" />
					<!-- <vxe-table-column prop="images" label="图片" align="center" style="display: flex">
						<template slot-scope="scope">
							<div style="display: flex;justify-content: center">
								<el-image
									v-if="scope.row.imagePreview != null"
									style="width: 32px; height: 32px"
									:src="scope.row.imagePreview"
									:preview-src-list="scope.row.imageList"
									:z-index="9999"
								>
									<div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
								</el-image>
								<span v-else></span>
							</div>
						</template>
					</vxe-table-column> -->
					<!--          <vxe-table-column prop="brandId" label="品牌id"/>-->
					<vxe-table-column prop="checkStatus" label="盘点状态" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.checkStatus === '1'">未盘</span>
							<span v-else-if="scope.row.checkStatus === '2'">储位异常</span>
							<span v-else-if="scope.row.checkStatus === '3'">已盘</span>
							<span v-else>未知状态</span>
						</template>
					</vxe-table-column>
				</template>
			</DsnSelectDialog>
		</div>
	</div>
</template>
<script>
import { addEquipments } from '@/api/efms/equipments/equipments.api';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { getStorageList } from '@/api/efms/equipments/equipment.storage.api';
import arrayToTree from 'array-to-tree';
import { getInventoryScheduleDetails } from '@/api/efms/inventory_schedule/inventory_schedule.api';

export default {
	name: 'inventoryScheduleEdit',
	components: {},
	data() {
		return {
			isEditStation: false, //是否可编辑站位
			scheduleForm: {
				scheduleNo: null,
				storageId: null,
				executorId: null
			},
			selectParams: {
				multiple: false,
				clearable: true,
				placeholder: 'test'
			},
			treeParams: {
				clickParent: true,
				filterable: false,
				'check-strictly': true,
				'default-expand-all': true,
				'expand-on-click-node': false,
				data: [],
				props: {
					children: 'children',
					label: 'label',
					disabled: 'false',
					value: 'value'
				}
			},
			scheduleDetailList: [],
			selectionList: [],
			labelWidth: '120px',
			storageFullList: [],
			storageNodeList: [],
			equipmentCategoryList: [],
			equipmentSubcategoryList: [],
			conditionList: [],
			mainDeptList: [],
			brandList: [],
			executorList: [],
			equipmentImportDialog: {
				helpText: data => {
					return data;
				},
				equipmentList: [],
				selectedEquipmentList: [],
				loading: false,
				equipmentDialog: false,
				equipmentForm: {
					equipmentCategoryId: null
				}
			},
			inventoryGroupLoading: false,
			
			showStoreTitle:'',
			showStoreTitle2:'',
		};
	},
	created() {
		this.operateType = this.$route.query.operateType;
		this.initSelectData();
		this.$nextTick(() => {
			this.cloneList = _.cloneDeep(JSON.parse(JSON.stringify(this.inventoryScheduleList)));
			this.scheduleForm = this.cloneList[0] || JSON.parse(localStorage.getItem('inventoryScheduleList'))[0];
			if (this.scheduleForm.id != null) {
				this.loadScheduleDetails(this.scheduleForm.id);
			}
		});
	},
	activated() {
		if (this.scheduleForm.id != null) {
			this.loadScheduleDetails(this.scheduleForm.id);
		}
		this.initSelectData();
	},
	computed: {
		...mapGetters(['inventoryScheduleList']),
		userFormRules: function() {
			let that = this;
			return {
				scheduleNo: [{ required: true, message: '请输入盘点单号', trigger: 'change' }],
				equipmentCategoryId: [
					{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('inventorySchedule.fields.category')}`,
						trigger: 'change'
					}
				],
				equipmentSubcategoryId: [
					{
						required: false,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('inventorySchedule.fields.subcategory')}`,
						trigger: 'change'
					}
				],
				executorId: [
					{
						required: true,
						message: `${that.$t('commmsg.pleaseInput')}${that.$t('inventorySchedule.fields.executor')}`,
						trigger: 'change'
					}
				]
			};
		}
	},
	methods: {
		handleBack() {
			this.$router.pushAndClear(
				{ name: 'equipmentInventorySchedule' },
				{
					deleteItem: this.$route,
					current: this.$route
				}
			);
		},
		checkAdd(formName) {
			let count = 0;
			formName.forEach(element => {
				this.$refs[element].validate(valid => {
					if (valid) {
						count++;
					} else {
						return false;
					}
				});
			});
			if (count >= 1) {
				this.messageBox({
					type: 'success',
					title: '保存',
					content: '是否保存数据?'
				})
					.then(() => {
						this.handleDataSave();
					})
					.catch(() => {
						this.notification().warn({
							body: '已取消保存'
						});
					});
			}
		},
		handleDataSave() {
			const postData = {};
			postData['equipmentCategoryId'] = this.equipmentForm.equipmentCategoryId;
			postData['equipmentSubcategoryId'] = this.equipmentForm.equipmentSubcategoryId;
			postData['assetNo'] = this.equipmentForm.assetNo;
			postData['sno'] = this.equipmentForm.sno;
			postData['name'] = this.equipmentForm.name;
			postData['modelType'] = this.equipmentForm.modelType;
			postData['brandId'] = this.equipmentForm.brandId;
			postData['acquisitionDate'] = this.equipmentForm.acquisitionDate;
			postData['userId'] = this.equipmentForm.userId;
			postData['deptId'] = this.equipmentForm.deptId;
			postData['directorId'] = this.equipmentForm.directorId;
			postData['directorDeptId'] = this.equipmentForm.directorDeptId;
			postData['conditionId'] = this.equipmentForm.conditionId;
			addEquipments(postData).then(result => {
				const { code, errMsg } = result.data;
				if (code === 200) {
					this.notification().success({
						body: '保存成功'
					});
					this.handleBack();
					return;
				}
				this.notification().error({
					body: errMsg
				});
			});
		},
		handleReset(formName) {
			if (this.operateType === 'add') {
				formName.forEach(element => {
					this.$refs[element].resetFields();
				});
			}
		},
		initSelectData() {
			// this.initCategoryList();
			// this.initBrandList();
			// this.initConditionList();
			// this.initUserList();
			this.initStorageList();
		},
		initCategoryList() {
			this.equipmentCategoryList.push(
				{
					id: '1',
					label: '电脑',
					value: '电脑',
					key: 'computer'
				},
				{
					id: '2',
					label: '消防设备',
					value: '消防设备',
					key: 'fire'
				}
			);
		},
		initSubcategory(categoryId) {},
		initBrandList() {
			this.brandList.push(
				{
					id: '1',
					label: '华硕',
					value: '华硕',
					key: 'asus'
				},
				{
					id: '2',
					label: '小米',
					value: 'xiaomi',
					key: 'xiaomi'
				}
			);
		},
		initConditionList() {
			this.conditionList.push(
				{
					id: '1',
					label: '正常',
					value: 'ok',
					key: 'ok'
				},
				{
					id: '2',
					label: '停用',
					value: 'stop',
					key: 'stop'
				}
			);
		},
		initUserList() {
			this.executorList.push(
				{
					id: '1',
					label: 'user1',
					value: '1',
					key: '1'
				},
				{
					id: '2',
					label: 'user2',
					value: '2',
					key: '2'
				}
			);
		},
		handleDetailTableSelectionChange() {},
		selectCheckBox() {},
		handleDetailAdd() {
			this.equipmentImportDialog.equipmentDialog = true;
		},
		handleDetailEdit() {},
		handleDetailDeleteCheck() {},
		handleSelectionWorkCenter() {},
		handleCancleWorCenter() {
			this.equipmentImportDialog.equipmentDialog = false;
		},
		queryWorkCenter() {},
		handleResetWorkCenterCondition() {},
		loadScheduleDetails(id) {
			this.inventoryGroupLoading = true;
			getInventoryScheduleDetails(id).then(res => {
				const { status, data } = res;
				if (status === 200 && data != null && data.data.length > 0) {
					let dataList = data.data;
					dataList.forEach(v => {
						let detailsList = v.detail;
						if (detailsList != null && detailsList.length > 0) {
							detailsList.forEach(z => {
								let images = z.images;
								if (images != null && images.length > 0) {
									let imageUrl = this.convertEquipmentImage(images);
									if (imageUrl != null && imageUrl.length > 0) {
										Object.assign(z, {
											imagePreview: imageUrl[0],
											imageList: imageUrl
										});
									}
								}
							});
						}
					});
					this.scheduleDetailList = [...dataList];
					console.info(this.scheduleDetailList);
				}
				this.inventoryGroupLoading = false;
			});
		},
		convertEquipmentImage(imgIds) {
			let result = [];
			if (imgIds != null && imgIds.length > 0) {
				let temp = imgIds.map(x => {
					// return `${VUE_APP_URL}/api/equipments/download/${x}`;
					return `https://cpm.deartsz.com/api/equipments/download/${x}`;
				});
				result = [...temp];
			}
			return result;
		},
		initStorageList() {
			let postData = {
				page: 0,
				pageSize: 0
			};
			getStorageList(postData).then(res => {
				const { status, data } = res;
				if (status != null && data != null && data.data.length > 0) {
					this.storageFullList = [...data.data];
					let tempList = data.data.map(v => {
						return {
							label: v.equipmentStorageName,
							id: v.id,
							value: v.id,
							parentId: v.parentId == null || v.parentId === '' ? null : v.parentId,
							children: null
						};
					});
					let resultList = arrayToTree(tempList, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					let jsonArray = JSON.stringify(resultList);
					this.storageNodeList = [...JSON.parse(jsonArray)];
					this.treeParams.data = [...JSON.parse(jsonArray)];
					// this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
					console.info(this.storageNodeList);
				} else {
					this.storageNodeList = [];
				}
			});
		},
		keeperNameFormat(scope) {
			let storage = scope.row.storage;
			let nameArr = [];
			if (storage != null && storage.keepers != null && storage.keepers.length > 0) {
				storage.keepers.forEach(v => {
					nameArr.push(v.realname);
				});
			}
			return nameArr.length > 0 ? nameArr.join(',') : null;
		},
		handleViewItemDetail(scope) {
			console.log(scope);
			// this.showStoreTitle2 = "明细列表";
			this.showStoreTitle = "当前储位："+ ' ' + scope.row.storage.storageName;
			
			let dataList = JSON.parse(JSON.stringify(scope.row.detail));
			this.equipmentImportDialog.equipmentDialog = true;
			this.equipmentImportDialog.equipmentList = [...dataList];
			console.info(dataList);
		},
		storageInventoryScheduleFormat(data) {
			if (data === 0) {
				return '盘点中';
			} else if (data === 1) {
				return '已完成';
			} else {
				return '未知';
			}
		}
	}
};
</script>
<style lang="scss">
.equipment-inventory-schedule-edit-container {
	.operate {
		padding: 16px;

		.el-button {
			margin: 0 8px 0 0;
		}
	}

	.info {
		background-color: #ffffff;
		padding: 16px;

		.equipmentFormTop {
			.el-form-item {
				margin: 0 24px 16px 0;
			}
		}

		.equipment-main-form {
			.el-form-item {
				margin: 0 24px 16px 0;

				.el-select {
					width: 320px;
				}

				.el-input {
					width: 320px;
				}
			}
		}
	}

	.equipment-query-form {
		.el-form-item__label {
			padding: 0;
		}
	}
	.vxe-header--column{
		  background:#4e81bd;font-family:微软雅黑;color:#ffffff;border-right:1px #e8eaec solid;
	}
}
</style>
