import request from "@/service/";


export const addInventorySchedule = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/inventorySchedules`,
        data
    );
}

export const updateInventorySchedule = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/inventorySchedules`,
        data
    );
}


export const getInventoryScheduleList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/inventorySchedules`,
        {
            params: params
        }
    );
}

export const getInventoryScheduleById = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/inventorySchedules/${id}`,
    );
}

export const getInventoryScheduleDetails = (scheduleId) => {
    return request.get(
        `${window.VUE_APP_URL}/api/inventorySchedules/${scheduleId}/detail`,
    );
}


export const startSchedule = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/inventorySchedules/${data['id']}/start`,
        data);
}


export const abortSchedule = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/inventorySchedules/${data['id']}/abort`,
        data);
}

export const finishSchedule = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/inventorySchedules/${data.id}/finish`,
        data
    );
}


export const deleteOneById = (id) => {
    return request.delete(
        `${window.VUE_APP_URL}/api/inventorySchedules/${id}`,
    );
}

/***
 * 导出盘点报表
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const downloadInventoryReport = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/inventorySchedules/${id}/exportDetailReport`,
        {
            responseType:'blob'
        }
    );
}
