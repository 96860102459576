/***
 * 将设备列表按照储位分组
 * @param equipmentList
 */
export const convertToGroupList = (equipmentList, storageFullList) => {
    if (equipmentList == null || equipmentList.length <= 0) {
        return []
    } else {
        let resultList = [];
        let storageIdList = new Set(equipmentList.map(s => s.storageId));
        if (storageFullList != null && storageFullList.length > 0) {
            if (storageIdList != null && storageIdList.size > 0) {
                storageIdList.forEach((v) => {
                    let targetStorages = storageFullList.filter(s => s.id === v)
                    if (targetStorages != null && targetStorages.length === 1) {
                        let eqs = equipmentList.filter(e => e.storageId === v);
                        resultList.push({
                            storage: {
                                id: targetStorages[0].id,
                                storageName: targetStorages[0].equipmentStorageName,
                                keepers: targetStorages[0].keepers
                            },
                            actualCount: 0,
                            needToCount: eqs == null || eqs.length <= 0 ? 0 : eqs.length,
                            errorCount: 0,
                            unCount: eqs == null || eqs.length <= 0 ? 0 : eqs.length,
                            status: "-1",
                            detail: eqs == null || eqs.length <= 0 ? null : eqs.map(z => {
                                return {
                                    id: z.id,
                                    checkStatus: "1",
                                    equipmentAssetNo: z.equipmentAssetNo,
                                    equipmentName: z.equipmentName,
                                    modelType: z.modelType,
                                    storageId: z.storageId,
                                    storageName: targetStorages[0].equipmentStorageName,
                                    brandName: z.brandName,
                                    imgIds:z.imgIds
                                }
                            })
                        })
                    }
                })

            }
        }
        console.info(resultList)
        return resultList;
    }
}
